/*
*火车票退票单未出账单列表
*/
const __request = require(`./__request/__request_contentType_json`);

const consumer_credit_trainRefundNoYetBillList = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/credit/trainRefundNoYetBillList',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_credit_trainRefundNoYetBillList;