/*
*获取未出账单,酒店订单列表
*/
const __request = require(`./__request/__request_contentType_json`);

const consumer_credit_hotelBillList = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/credit/hotelBillList',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_credit_hotelBillList;